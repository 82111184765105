@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oswald&display=swap");
body {
  min-width: 1200px;
}

@media (max-width: 425px) {
  body {
    min-width: auto;
  }
}

body * {
  font-family: 'Noto Sans JP', sans-serif;
}

.main_visual {
  height: 100vh;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 20px solid #fff;
  background: url("/images/main_visual.png") no-repeat;
  background-size: cover;
  margin: 0 0 30px 0;
}

@media (max-width: 425px) {
  .main_visual {
    height: 500px;
    padding: 20px;
    text-align: left;
  }
}

.main_visual--title {
  width: 170px;
  margin: 0 0 0 0;
  text-align: center;
  font-family: serif;
  color: #d09595;
  font-size: 31px;
  position: absolute;
  left: 50px;
  top: 50px;
}

@media (max-width: 425px) {
  .main_visual--title {
    font-size: 20px;
    text-align: left;
    box-sizing: border-box;
  }
}

.main_visual--sub_title {
  color: #d09595;
  font-size: 14px;
  display: block;
  margin: 5px 0 0 0;
}

@media (max-width: 425px) {
  .main_visual--sub_title {
    font-size: 12px;
  }
}

.main_visual--catch {
  font-size: 30px;
  line-height: 3;
  text-align: right;
  width: 100%;
  font-family: serif;
  color: #d09595;
  letter-spacing: 5px;
}

@media (max-width: 425px) {
  .main_visual--catch {
    font-size: 18px;
    text-align: left;
    line-height: 1.8;
  }
  .main_visual--catch br {
    display: none;
  }
}

.global_nav {
  display: flex;
  justify-content: center;
  margin: 0 0 100px 0;
}

@media (max-width: 425px) {
  .global_nav {
    display: none;
  }
}

.global_nav--link {
  font-family: serif;
  font-size: 13px;
  text-decoration: none;
  color: #000;
  margin: 0 3px;
  transition: 0.15s;
}

.global_nav--link:after {
  content: '/';
  padding: 0 6px;
}

.global_nav--link:last-child:after {
  display: none;
}

.global_nav--link:hover {
  color: #d09595;
}

.news {
  height: 60px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 90px auto;
}

@media (max-width: 425px) {
  .news {
    padding: 0 20px;
    flex-direction: column;
    height: auto;
    margin-bottom: 60px;
  }
}

.news--title {
  background: #D09595;
  height: 60px;
  color: #fff;
  text-align: center;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Oswald';
}

@media (max-width: 425px) {
  .news--title {
    width: 100%;
  }
}

.news--description {
  background: rgba(208, 149, 149, 0.2);
  flex: 1;
  font-size: 14px;
  line-height: 1.5;
  height: 60px;
  align-items: center;
  display: flex;
  padding: 0 30px;
  color: #333;
}

@media (max-width: 425px) {
  .news--description {
    flex: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 15px;
  }
}

.reason {
  max-width: 1200px;
  margin: 0 auto 90px auto;
}

@media (max-width: 425px) {
  .reason {
    padding: 0 20px;
    margin-bottom: 60px;
  }
}

.reason--title {
  font-size: 26px;
  margin: 0 0 35px 0;
  text-align: center;
}

.reason--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.reason--description {
  font-size: 14px;
  text-align: center;
  line-height: 2.5;
  color: #707070;
}

@media (max-width: 425px) {
  .reason--description {
    text-align: left;
    line-height: 1.8;
  }
  .reason--description br {
    display: none;
  }
}

.introduction {
  background: #f9f9f9;
  padding: 90px;
  margin: 0 0 90px 0;
}

@media (max-width: 425px) {
  .introduction {
    padding: 40px 20px;
    margin-bottom: 0;
  }
}

.introduction--box {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .introduction--box {
    align-items: start;
    flex-direction: column;
  }
}

.introduction--img {
  max-width: 100%;
}

@media (max-width: 425px) {
  .introduction--img {
    margin-bottom: 30px;
  }
}

.introduction--content {
  width: calc(100% - 744px);
}

@media (max-width: 425px) {
  .introduction--content {
    width: 100%;
  }
}

.introduction--title {
  font-size: 26px;
  margin: 0 0 35px 0;
}

.introduction--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.introduction--description {
  font-size: 14px;
  line-height: 2;
  color: #707070;
}

.about {
  max-width: 1200px;
  margin: 0 auto 90px auto;
  background: url("/images/about.png") no-repeat;
  background-size: cover;
  height: 350px;
  display: flex;
  align-items: center;
  padding: 80px;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .about {
    height: auto;
    padding: 20px;
    background-position: right;
    margin-bottom: 60px;
  }
}

.about--box {
  width: 500px;
}

.about--title {
  font-size: 26px;
  margin: 0 0 35px 0;
}

@media (max-width: 425px) {
  .about--title {
    color: #fff;
  }
}

.about--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.about--description {
  font-size: 14px;
  line-height: 2;
  color: #707070;
}

@media (max-width: 425px) {
  .about--description {
    color: #fff;
  }
  .about--description br {
    display: none;
  }
}

.line {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 90px auto;
  padding: 0 80px;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .line {
    padding: 0 20px;
    flex-direction: column;
    margin-bottom: 60px;
  }
}

@media (max-width: 425px) {
  .line--img {
    width: 140px;
    margin: 0 auto 30px auto;
  }
}

.line--content {
  width: 700px;
}

@media (max-width: 425px) {
  .line--content {
    width: 100%;
    padding: 0 20px;
  }
}

.line--title {
  font-size: 20px;
  margin: 0 0 35px 0;
  line-height: 1.8;
}

.line--description {
  font-size: 14px;
  line-height: 2;
  color: #707070;
}

.service {
  margin: 0 auto 90px auto;
}

@media (max-width: 425px) {
  .service {
    margin-bottom: 60px;
  }
}

.service--top_box {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  color: #fff;
}

@media (max-width: 425px) {
  .service--top_box {
    flex-direction: column;
  }
}

.service--content {
  width: 33.3%;
  height: 450px;
  align-items: flex-end;
  display: flex;
  padding: 0 0 15px 0;
  line-height: 1.8;
  text-align: center;
  justify-content: center;
  letter-spacing: 2px;
}

.service--content:nth-child(1) {
  background: url("/images/service--content-01.png") no-repeat;
  background-size: cover;
}

.service--content:nth-child(2) {
  background: url("/images/service--content-02.png") no-repeat;
  background-size: cover;
}

.service--content:nth-child(3) {
  background: url("/images/service--content-03.png") no-repeat;
  background-size: cover;
}

@media (max-width: 425px) {
  .service--content {
    width: 100%;
    height: 250px;
    align-items: center;
  }
}

.service--bottom_box {
  height: 415px;
  background: #242424;
  color: #fff;
  margin: -200px 0 0 0;
  padding: 240px 0 0 0;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .service--bottom_box {
    height: auto;
    margin: 0;
    padding: 40px 20px;
  }
}

.service--description {
  max-width: 1100px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 2;
  color: #fff;
}

.flow {
  margin: 0 0 90px 0;
}

@media (max-width: 425px) {
  .flow {
    margin-bottom: 60px;
  }
}

.flow--title {
  font-size: 26px;
  margin: 0 0 35px 0;
  text-align: center;
}

.flow--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.flow--description {
  font-size: 14px;
  line-height: 2;
  color: #333;
}

.flow--box {
  display: flex;
  max-width: 1200px;
  margin: 0 auto 40px auto;
}

@media (max-width: 425px) {
  .flow--box {
    flex-direction: column;
  }
}

.flow--content {
  width: 25%;
  padding: 35px 20px;
  box-sizing: border-box;
}

.flow--content:nth-child(1) {
  background: rgba(208, 149, 149, 0.4);
}

.flow--content:nth-child(2) {
  background: rgba(208, 149, 149, 0.6);
}

.flow--content:nth-child(3) {
  background: rgba(208, 149, 149, 0.8);
}

.flow--content:nth-child(4) {
  background: #d09595;
}

@media (max-width: 425px) {
  .flow--content {
    width: 100%;
  }
}

.flow--content_title {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin: 0 0 12px 0;
}

.flow--number {
  font-size: 35px;
  margin: 0 15px 0 10px;
  font-family: 'Oswald';
}

.flow--link {
  width: 380px;
  margin: 0 auto;
  height: 90px;
  text-decoration: none;
  color: #0a0a0a;
  text-align: center;
  background: rgba(208, 149, 149, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #edebeb;
  position: relative;
  transition: 0.15s;
}

.flow--link:before {
  content: '';
  width: 80px;
  height: 1px;
  background: #d6d6d6;
  position: absolute;
  right: -40px;
}

.flow--link:after {
  content: '';
  width: 8px;
  background: #d6d6d6;
  position: absolute;
  right: -41px;
  top: 42px;
  height: 1px;
  transform: rotate(45deg);
}

.flow--link:hover {
  background: rgba(208, 149, 149, 0.4);
}

@media (max-width: 425px) {
  .flow--link {
    width: 280px;
  }
}

.flow--link_title {
  font-family: Serif;
  font-size: 12px;
  display: block;
  margin: 8px 0 0 0;
}

.price {
  margin: 0 0 90px 0;
}

@media (max-width: 425px) {
  .price {
    margin-bottom: 60px;
  }
}

.price--title {
  font-size: 26px;
  margin: 0 0 35px 0;
  text-align: center;
}

.price--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.price--description {
  font-size: 14px;
  line-height: 2;
  text-align: center;
  margin: 0 0 40px 0;
}

@media (max-width: 425px) {
  .price--description {
    text-align: left;
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .price--description br {
    display: none;
  }
}

.price_box {
  display: flex;
  max-width: 1200px;
  margin: 0 auto 80px auto;
  justify-content: space-between;
  align-items: start;
}

@media (max-width: 425px) {
  .price_box {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.price_box-right {
  flex-direction: row-reverse;
  margin: 0 auto 40px auto;
}

@media (max-width: 425px) {
  .price_box-right {
    flex-direction: column;
  }
}

.price_box--img {
  max-width: 100%;
}

.price_box--content {
  width: 550px;
  background: #9d5c5c;
  margin: 140px 0 0 0;
}

@media (max-width: 425px) {
  .price_box--content {
    width: 100%;
    margin-top: 0;
  }
}

.price_box--content-right {
  margin: -40px 0 0 0;
  background: #bea0a0;
}

@media (max-width: 425px) {
  .price_box--content-right {
    margin-top: 0;
  }
}

.price_box--title {
  color: #fff;
  font-family: 'Oswald';
  font-size: 40px;
  padding: 30px 40px;
}

.price_box--sub_title {
  font-size: 18px;
}

.price_box--row {
  font-family: 'Oswald';
  font-size: 40px;
  color: #554545;
  padding: 30px 40px;
}

.price_box--row:nth-child(even) {
  background: rgba(255, 255, 255, 0.6);
}

.price_box--note {
  font-size: 20px;
}

.cancel_price {
  max-width: 1200px;
  margin: 0 auto 40px auto;
  height: 150px;
  display: flex;
}

@media (max-width: 425px) {
  .cancel_price {
    flex-direction: column;
    height: auto;
  }
}

.cancel_price--title {
  color: #fff;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  font-size: 30px;
}

@media (max-width: 425px) {
  .cancel_price--title {
    padding: 20px 0;
    width: 100%;
  }
}

.cancel_price--content {
  flex: 1;
  text-align: center;
  font-size: 20px;
  color: #554545;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  background: #f7f7f7;
}

@media (max-width: 425px) {
  .cancel_price--content {
    padding: 30px 0;
    border-top: 1px solid #fff;
  }
}

.cancel_price--cost {
  font-size: 40px;
  color: #D09595;
  display: blcok;
  margin: 20px 0 0 0;
  font-weight: bold;
  font-family: 'Oswald';
}

.cancel_price--small {
  font-size: 20px;
}

.note_list {
  font-size: 14px;
  max-width: 1200px;
  margin: 0 auto;
  color: #707070;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .note_list {
    padding: 0 20px;
  }
}

.note_list--item {
  text-indent: -1em;
  padding: 0 0 0 1em;
}

.attention {
  background: #f9f9f9;
  padding: 90px;
}

@media (max-width: 425px) {
  .attention {
    padding: 35px 20px;
  }
}

.attention--title {
  font-size: 26px;
  margin: 0 0 35px 0;
  text-align: center;
}

.attention--sub_title {
  font-size: 12px;
  font-family: Serif;
  margin: 15px 0 0 0;
  display: block;
}

.attention--box {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .attention--box {
    flex-direction: column;
  }
}

.attention--content {
  width: calc(33.3% - 60px);
}

@media (max-width: 425px) {
  .attention--content {
    width: 100%;
    margin-top: 30px;
  }
  .attention--content:first-of-type {
    margin-top: 0;
  }
}

.attention--content_title {
  font-size: 20px;
  margin: 0 0 30px 0;
  line-height: 1.4;
  text-align: center;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}

.attention--description {
  font-size: 14px;
  line-height: 2;
  color: #707070;
}

.footer {
  background: #2d2d2d;
  color: #fff;
  padding: 60px 0;
  box-sizing: border-box;
}

.footer--box {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .footer--box {
    flex-direction: column;
  }
}

.footer--content {
  width: calc(50% - 50px);
}

@media (max-width: 425px) {
  .footer--content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .footer--content:last-child {
    margin-top: 30px;
  }
}

.footer--title {
  font-size: 20px;
  margin: 0 0 30px 0;
}

.footer--description {
  font-size: 14px;
  margin: 0 0 15px 0;
  line-height: 1.5;
}

.footer--tel {
  font-size: 40px;
  font-family: 'Oswald';
  margin: 0 0 30px 0;
}

.footer--tel a {
  font-family: 'Oswald' !important;
  color: #ffffff !important;
}

.footer--link {
  background: #fff;
  color: #333;
  text-decoration: none;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-direction: column;
  transition: 0.15s;
}

.footer--link:hover {
  background: #f1f1f1;
}

.footer--link_sub {
  font-size: 12px;
  display: block;
  margin: 5px 0 0 0;
}

.footer--list {
  font-size: 14px;
  margin: 0 0 30px 0;
  line-height: 2;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
}

.footer--item_title {
  width: 150px;
  display: inline-block;
}

.footer--mail {
  color: #fff;
}

.footer iframe {
  max-width: 90%;
}

@media (max-width: 425px) {
  .footer iframe {
    width: 100%;
    max-width: 100%;
  }
}
