@charset "UTF-8";
@import "../mixin";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
$colorPink: #d09595;
body {

  min-width: 1200px;
  @include sp {
    min-width: auto;
  }
  * {
    font-family: 'Noto Sans JP', sans-serif;
  }
}
.main_visual {
  height: 100vh;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 20px solid #fff;
  background: url('/images/main_visual.png') no-repeat;
  background-size: cover;
  margin: 0 0 30px 0;
  @include sp {
    height: 500px;
    padding: 20px;
    text-align: left;
  }
  &--title {
    width: 170px;
    margin: 0 0 0 0;
    text-align: center;
    font-family: serif;
    color: #d09595;
    font-size: 31px;
    position: absolute;
    left: 50px;
    top: 50px;
    @include sp {
      font-size: 20px;
      text-align: left;
      box-sizing: border-box;
    }
  }
  &--sub_title {
    color: #d09595;
    font-size: 14px;
    display: block;
    margin: 5px 0 0 0;
    @include sp {
      font-size: 12px;
    }
  }
  &--catch {
    font-size: 30px;
    line-height: 3;
    text-align: right;
    width: 100%;
    font-family: serif;
    color: #d09595;
    letter-spacing: 5px;
    @include sp {
      font-size: 18px;
      text-align: left;
      line-height: 1.8;
      br {
        display: none;
      }
    }
  }
}
.global_nav {
  display: flex;
  justify-content: center;
  margin: 0 0 100px 0;
  @include sp {
    display: none;
  }
  &--link {
    font-family: serif;
    font-size: 13px;
    text-decoration: none;
    color: #000;
    margin: 0 3px;
    transition: 0.15s;
    &:after {
      content: '/';
      padding: 0 6px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:hover {
      color: $colorPink;
    }
  }
}
.news {
  height: 60px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto 90px auto;
  @include sp {
    padding: 0 20px;
    flex-direction: column;
    height: auto;
    margin-bottom: 60px;
  }
  &--title {
    background: #D09595;
    height: 60px;
    color: #fff;
    text-align: center;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Oswald';
    @include sp {
      width: 100%;
    }
  }
  &--description {
    background: rgba(208, 149, 149, 0.2);
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
    height: 60px;
    align-items: center;
    display: flex;
    padding: 0 30px;
    color: #333;
    @include sp {
      flex: auto;
      width: 100%;
      box-sizing: border-box;
      padding: 30px 15px;
    }
  }
}
.reason {
  max-width: 1200px;
  margin: 0 auto 90px auto;
  @include sp {
    padding: 0 20px;
    margin-bottom: 60px;
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
    text-align: center;
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--description {
    font-size: 14px;
    text-align: center;
    line-height: 2.5;
    color: #707070;
    @include sp {
      text-align: left;
      line-height: 1.8;
      br {
        display: none;
      }
    }
  }
}
.introduction {
  background: #f9f9f9;
  padding: 90px;
  margin: 0 0 90px 0;
  @include sp {
    padding: 40px 20px;
    margin-bottom: 0;
  }
  &--box {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include sp {
      align-items: start;
      flex-direction: column;
    }
  }
  &--img {
    max-width: 100%;
    @include sp {
      margin-bottom: 30px;
    }
  }
  &--content {
    width: calc(100% - 744px);
    @include sp {
      width: 100%;
    }
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    color: #707070;
  }
}
.about {
  max-width: 1200px;
  margin: 0 auto 90px auto;
  background: url('/images/about.png') no-repeat;
  background-size: cover;
  height: 350px;
  display: flex;
  align-items: center;
  padding: 80px;
  box-sizing: border-box;
  @include sp {
    height: auto;
    padding: 20px;
    background-position: right;
    margin-bottom: 60px;
  }
  &--box {
    width: 500px;
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
    @include sp {
      color: #fff;
    }
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    color: #707070;
    @include sp {
      color: #fff;
      br {
        display: none;
      }
    }
  }
}
.line {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 90px auto;
  padding: 0 80px;
  box-sizing: border-box;
  @include sp {
    padding: 0 20px;
    flex-direction: column;
    margin-bottom: 60px;
  }
  &--img {
    @include sp {
      width: 140px;
      margin: 0 auto 30px auto;
    }
  }
  &--content {
    width: 700px;
    @include sp {
      width: 100%;
      padding: 0 20px;
    }
  }
  &--title {
    font-size: 20px;
    margin: 0 0 35px 0;
    line-height: 1.8;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    color: #707070;
  }
}
.service {
  margin: 0 auto 90px auto;
  @include sp {
    margin-bottom: 60px;
  }
  &--top_box {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    color: #fff;
    @include sp {
      flex-direction: column;
    }
  }
  &--content {
    width: 33.3%;
    height: 450px;
    align-items: flex-end;
    display: flex;
    padding: 0 0 15px 0;
    line-height: 1.8;
    text-align: center;
    justify-content: center;
    letter-spacing: 2px;
    &:nth-child(1) {
      background: url('/images/service--content-01.png') no-repeat;
      background-size: cover;
    }
    &:nth-child(2) {
      background: url('/images/service--content-02.png') no-repeat;
      background-size: cover;
    }
    &:nth-child(3) {
      background: url('/images/service--content-03.png') no-repeat;
      background-size: cover;
    }
    @include sp {
      width: 100%;
      height: 250px;
      align-items: center;
    }
  }
  &--title {
  }
  &--bottom_box {
    height: 415px;
    background: #242424;
    color: #fff;
    margin: -200px 0 0 0;
    padding: 240px 0 0 0;
    box-sizing: border-box;
    @include sp {
      height: auto;
      margin: 0;
      padding: 40px 20px;
    }
  }
  &--description {
    max-width: 1100px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 2;
    color: #fff;
  }
}
.flow {
  margin: 0 0 90px 0;
  @include sp {
    margin-bottom: 60px;
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
    text-align: center;
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    color: #333;
  }
  &--box {
    display: flex;
    max-width: 1200px;
    margin: 0 auto 40px auto;
    @include sp {
      flex-direction: column;
    }
  }
  &--content {
    width: 25%;
    padding: 35px 20px;
    box-sizing: border-box;
    &:nth-child(1) {
      background: rgba(208, 149, 149, 0.4);
    }
    &:nth-child(2) {
      background: rgba(208, 149, 149, 0.6);
    }
    &:nth-child(3) {
      background: rgba(208, 149, 149, 0.8);
    }
    &:nth-child(4) {
      background: rgba(208, 149, 149, 1);
    }
    @include sp {
      width: 100%;
    }
  }
  &--content_title {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin: 0 0 12px 0;
  }
  &--number {
    font-size: 35px;
    margin: 0 15px 0 10px;
    font-family: 'Oswald';
  }
  &--link {
    width: 380px;
    margin: 0 auto;
    height: 90px;
    text-decoration: none;
    color: #0a0a0a;
    text-align: center;
    background: rgba(208, 149, 149, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #edebeb;
    position: relative;
    transition: 0.15s;
    &:before {
      content: '';
      width: 80px;
      height: 1px;
      background: #d6d6d6;
      position: absolute;
      right: -40px;
    }
    &:after {
      content: '';
      width: 8px;
      background: #d6d6d6;
      position: absolute;
      right: -41px;
      top: 42px;
      height: 1px;
      transform: rotate(45deg);
    }
    &:hover {
      background: rgba(208, 149, 149, 0.4);
    }
    @include sp {
      width: 280px;
    }
  }
  &--link_title {
    font-family: Serif;
    font-size: 12px;
    display: block;
    margin: 8px 0 0 0;
  }
}
.price {
  margin: 0 0 90px 0;
  @include sp {
    margin-bottom: 60px;
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
    text-align: center;
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    text-align: center;
    margin: 0 0 40px 0;
    @include sp {
      text-align: left;
      padding: 0 20px;
      margin-bottom: 30px;
      br {
        display: none;
      }
    }
  }
}
.price_box {
  display: flex;
  max-width: 1200px;
  margin: 0 auto 80px auto;
  justify-content: space-between;
  align-items: start;
  @include sp {
    flex-direction: column;
    margin-bottom: 30px;
  }
  &-right {
    flex-direction: row-reverse;
    margin: 0 auto 40px auto;
    @include sp {
      flex-direction: column;
    }
  }
  &--img {
    max-width: 100%;
  }
  &--content {
    width: 550px;
    background: #9d5c5c;
    margin: 140px 0 0 0;
    @include sp {
      width: 100%;
      margin-top: 0;
    }
    &-right {
      margin: -40px 0 0 0;
      background: #bea0a0;
      @include sp {
        margin-top: 0;
      }
    }
  }
  &--title {
    color: #fff;
    font-family: 'Oswald';
    font-size: 40px;
    padding: 30px 40px;
  }
  &--sub_title {
    font-size: 18px;
  }
  &--row {
    font-family: 'Oswald';
    font-size: 40px;
    color: #554545;
    padding: 30px 40px;
    &:nth-child(even) {
      background: rgba(255, 255, 255, 0.6);
    }
  }
  &--note {
    font-size: 20px;
  }
}
.cancel_price {
  max-width: 1200px;
  margin: 0 auto 40px auto;
  height: 150px;
  display: flex;
  @include sp {
    flex-direction: column;
    height: auto;
  }
  &--title {
    color: #fff;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    font-size: 30px;
    @include sp {
      padding: 20px 0;
      width: 100%;
    }
  }
  &--content {
    flex: 1;
    text-align: center;
    font-size: 20px;
    color: #554545;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    background: #f7f7f7;
    @include sp {
      padding: 30px 0;
      border-top: 1px solid #fff;
    }
  }
  &--cost {
    font-size: 40px;
    color: #D09595;
    display: blcok;
    margin: 20px 0 0 0;
    font-weight: bold;
    font-family: 'Oswald';
  }
  &--small {
    font-size: 20px;
  }
}
.note_list {
  font-size: 14px;
  max-width: 1200px;
  margin: 0 auto;
  color: #707070;
  line-height: 1.8;
  @include sp {
    padding: 0 20px;
  }
  &--item {
    text-indent: -1em;
    padding: 0 0 0 1em;
  }
}
.attention {
  background: #f9f9f9;
  padding: 90px;
  @include sp {
    padding: 35px 20px;
  }
  &--title {
    font-size: 26px;
    margin: 0 0 35px 0;
    text-align: center;
  }
  &--sub_title {
    font-size: 12px;
    font-family: Serif;
    margin: 15px 0 0 0;
    display: block;
  }
  &--box {
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }
  }
  &--content {
    width: calc(33.3% - 60px);
    @include sp {
      width: 100%;
      margin-top: 30px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  &--content_title {
    font-size: 20px;
    margin: 0 0 30px 0;
    line-height: 1.4;
    text-align: center;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }
  &--description {
    font-size: 14px;
    line-height: 2;
    color: #707070;
  }
}
.footer {
  background: #2d2d2d;
  color: #fff;
  padding: 60px 0;
  box-sizing: border-box;
  &--box {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }
  }
  &--content {
    width: calc(50% - 50px);
    @include sp {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      &:last-child {
        margin-top: 30px;
      }
    }
  }
  &--title {
    font-size: 20px;
    margin: 0 0 30px 0;
  }
  &--description {
    font-size: 14px;
    margin: 0 0 15px 0;
    line-height: 1.5;
  }
  &--tel {
    font-size: 40px;
    font-family: 'Oswald';
    margin: 0 0 30px 0;
    a {
      font-family: 'Oswald' !important;
      color: #ffffff !important;
    }
  }
  &--link {
    background: #fff;
    color: #333;
    text-decoration: none;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    flex-direction: column;
    transition: 0.15s;
    &:hover {
      background: #f1f1f1;
    }
  }
  &--link_sub {
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
  }
  &--list {
    font-size: 14px;
    margin: 0 0 30px 0;
    line-height: 2;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
  }
  &--item {
  }
  &--item_title {
    width: 150px;
    display: inline-block;
  }
  &--mail {
    color: #fff;
  }
  iframe {
    max-width: 90%;
    @include sp {
      width: 100%;
      max-width: 100%;
    }
  }
}
